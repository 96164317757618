<template>
  <div class="row">
    <div class="col">
      <label v-if="label">{{ label }}</label>
      <date-picker range v-model="range"
        placeholder="Select datetime range"
        :shortcuts="shortcuts"
        :disabled-date="disabledDate"
        :disabled="disabled"
        :value-type="valueType"
        @input="rangeChanged"
      ></date-picker>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import DatePicker from 'vue2-datepicker'

export default {
  components: { DatePicker },
  props: {
    typeButton: {
      type: String,
      default: 'text'
    },
    editMode: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledDate: {
      type: Function
    },
    label: {
      type: String,
    },
    // must be included in props
    value: {
      type: null
    },
    valueType: {
      default: 'format'
    }
  },
  data() {
    return {
      range: null,
      shortcuts: [
      {
        text: 'Today',
        onClick() {
          const date = new Date()
          return [date, date]
        },
      }, {
        text: 'Yesterday',
        onClick() {
          const date = new Date()
          date.setTime(date.getTime() - 3600 * 1000 * 24)
          return [date, date]
        },
      },
      {
        text: 'Last 7 days',
        onClick() {
          return [new Date().add(-7).days(), new Date().add(-1).day()]
        },
      },
      {
        text: 'Last Week',
        onClick() {
          var a = new Date().last().week().last().monday()
          return [new Date(a).add(-1).week(), new Date(a).add(-1).day()]
        },
      },
      {
        text: 'Last 2 Weeks',
        onClick() {
          const s = new Date().add(-2).week().first().day()
          const e = new Date().add(-2).week().first().day().add(13).days()
          return [s, e]
        },
      },
      {
        text: 'This Month',
        onClick() {
          var s = new Date().moveToFirstDayOfMonth()
          var e = new Date(s).moveToLastDayOfMonth()
          return [s, e]
        },
      },
      {
        text: 'Last Month',
        onClick() {
          var s = new Date().add(-1).month().moveToFirstDayOfMonth()
          var e = new Date(s).moveToLastDayOfMonth()
          return [s, e]
        },
      },
      {
        text: 'This Year',
        onClick() {
          var s = new Date(new Date().getFullYear(), 0, 1)
          var e = new Date(new Date().getFullYear(), 11, 31)
          return [s, e]
        },
      }, ],
    }
  },
  mounted() {
    var dates = []
    if (typeof(this.value) == 'string') {
      dates = this.value.split('~')
      this.range = [ new Date(dates[0]).format('Y-m-d'), new Date(dates[1]).format('Y-m-d') ]
      this.rangeChanged()
    }
    if (typeof(this.value) == 'object') {
      this.range = this.value
      this.rangeChanged()
    }
  },
  computed: {
    hasActiveRange() {
      return this.value && this.value[0] && this.value[1] && (this.value[0].length > 0) && (this.value[1].length > 0)
    }
  },
  methods: {
    rangeChanged() {
      this.$emit('input', this.range)
    }
  }
}
</script>
